import React from 'react'
import {
	Header,
	Button,
	Item,
	Popup,
	Input,
	CheckboxOriginal,
	Checkbox,
} from '../../components'

import { useDispatch, useSelector } from 'react-redux'
import { setRpcLinks, setActiveRpcLink } from '../../redux/slices/StorageSlice'

export const Rpc = () => {
	const dispatch = useDispatch()
	const { rpcLinks, activeRpcLink } = useSelector((state) => state.storage)
	const [showModalAdd, setShowModalAdd] = React.useState(false)
	const [showModalSuccess, setShowModalSuccess] = React.useState(false)
	const [showModalRestart, setShowModalRestart] = React.useState(false)
	const [disabledBtnAdd, setDisabledBtnAdd] = React.useState(true)
	const [rpcTitle, setRpcTitle] = React.useState('')

	const addNewRpc = () => {
		const newRpcItem = {
			id: Math.random(),
			title: rpcTitle,
			info: {
				ms: '102ms',
				height: 'Height: 16885049',
				status: 'green',
			},
		}
		dispatch(setRpcLinks(newRpcItem))
	}
	React.useEffect(() => {
		if (
			(rpcTitle.length > 5 && rpcTitle.toLowerCase().includes('https:')) ||
			(rpcTitle.length > 5 && rpcTitle.toLowerCase().includes('wc:'))
		) {
			setDisabledBtnAdd(false)
		} else {
			setDisabledBtnAdd(true)
		}
	}, [rpcTitle])

	const onAddRpc = () => {
		addNewRpc()
		setShowModalAdd(false)
		setShowModalSuccess(true)
		dispatch(setActiveRpcLink(rpcTitle))
	}

	const chooseRpcLink = (link) => {
		dispatch(setActiveRpcLink(link))
		setShowModalRestart(true)
	}

	const closeModalSuccess = () => {
		setShowModalSuccess(false)
		setShowModalRestart(true)
	}

	return (
		<div className='page'>
			<Header title='RPC Node' />
			<div className='page-inner'>
				<Item
					title='Whats the "Height" of nodes?'
					text='The larger number of height means that the nodes have more stable and in-time synchronization. When the nodes are connected at similar speeds, it is recommended to choose the one which has a higher height.'
				/>

				<div className='list-group'>
					<div className='list-title page-row-md'>Custom</div>
					<Button variant='default' onClick={() => setShowModalAdd(true)}>
						Add Custom Node
					</Button>
				</div>
				{rpcLinks.map((item) => (
					<ItemRpc
						activeRpcLink={activeRpcLink}
						onClick={chooseRpcLink}
						title={item.title}
						key={item.id}
						ms={item.info.ms}
						height={item.info.height}
						id={item.id}
					/>
				))}
				{/*Модалка*/}
				<div>
					<Popup
						modalIsOpen={showModalAdd}
						title='Add Custom Node'
						type='warning'
						label='Add Custom Node'>
						<div className='page-row'>
							<Input
								title='Add node'
								placeholder='https://'
								size='lg'
								handleChange={setRpcTitle}
								inputValue={rpcTitle}
							/>
						</div>
						<div className='page-actions'>
							<Button variant='inline' onClick={() => setShowModalAdd(false)}>
								Cancel
							</Button>
							<Button
								variant={disabledBtnAdd ? 'disabled' : 'default'}
								onClick={onAddRpc}>
								Confirm
							</Button>
						</div>
					</Popup>

					<Popup
						modalIsOpen={showModalSuccess}
						title='Success'
						titleTextStyled
						text='New custom RPC Node successfully added'
						label='Success'>
						<Button variant='default' onClick={closeModalSuccess}>
							Okay
						</Button>
					</Popup>

					<Popup
						modalIsOpen={showModalRestart}
						type='warning'
						title='Restart Required'
						titleTextStyled
						text='To finish switching the RPC node, Arbitrum needs to restart.'
						label='Restart Required'>
						<div className='page-actions'>
							<Button
								variant='inline'
								onClick={() => setShowModalRestart(false)}>
								Cancel
							</Button>
							<Button variant='default' to='/main'>
								Restart
							</Button>
						</div>
					</Popup>
				</div>
			</div>
		</div>
	)
}
const stylesItem = {
	item: {
		backgroundColor: 'var(--primary)',
		borderRadius: 8,
		padding: '15px 0',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		cursor: 'pointer',
		marginTop: 15,
	},
	par: {
		width: 268,
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		fontWeight: 500,
		fontSize: 14,
		color: 'var(--light)',
		marginTop: 0,
	},
	grey: {
		fontWeight: 300,
		fontSize: 14,
		lineHeight: '12px',
		color: 'var(--gray)',
		marginLeft: 20,
	},
	green: {
		fontWeight: 300,
		fontSize: 14,
		lineHeight: '12px',
		color: 'var(--green)',
	},
}
function ItemRpc({ title, height, ms, id, onClick, activeRpcLink }) {
	return (
		<div style={stylesItem.item} onClick={() => onClick(title)}>
			<div>
				<p style={stylesItem.par}>{title}</p>
				<div>
					<span style={stylesItem.green}>{ms}</span>
					<span style={stylesItem.grey}>{height}</span>
				</div>
			</div>

			<Checkbox
				isChecked={title === activeRpcLink}
				type='radio'
				variant='line'
				id={id}
				name='rpc-list'
			/>
		</div>
	)
}
