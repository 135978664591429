import React, { useState } from 'react'
import { Button, Header, Input, SubHeader } from '../../components'
import { generateMnemonic } from 'bip39';
// import setPhrase

export const CreateWallet = () => {
	const [disabledBtn, setDisabledBtn] = React.useState(true)
	const [name, setName] = React.useState('Wallet 1')
	const [phrase, setPhrase] = React.useState('')
	const [phraseArr, setPhraseArr] = React.useState('')
	React.useEffect(() => {
		let code = generateMnemonic()
		setPhrase(code)
		let arr = code.trim().split(' ')
		setPhraseArr(arr)
	}, [])

	React.useEffect(() => {
		if (name.length > 2) {
			setDisabledBtn(false)
		} else {
			setDisabledBtn(true)
		}
	}, [name])

	return (
		<div className='page'>
			<Header title='Create Wallet' />
			<div className='page-inner'>
				<SubHeader
				title='Enter a name for this wallet:'
				icon='wallet-md'
				/>
				<Input
					handleChange={setName}
					inputValue={name}
					placeholder='Wallet Name'
					size='lg'
				/>
			</div>
			<div className='page-inner page-align-bottom'>
				<div className="page-actions">
					<Button variant='inline' to={-1}>
						Cancel
					</Button>
					<Button variant={disabledBtn ? 'disabled' : 'default'} to='/recoveryWords' state={{phrase, phraseArr}}>
						Create Wallet
					</Button>
				</div>
			</div>
		</div>
	)
}
