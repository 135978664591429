import React from 'react'
import { Header, SubHeader, Button } from '../../components'
import styles from './import.module.css'

const mockList = [
	{
		title: 'Use a 12- words recovery phrase',
	},
	{
		title: 'Use a 15- words recovery phrase',
	},
	{
		title: 'Use a 18- words recovery phrase',
	},
	{
		title: 'Use a 21- words recovery phrase',
	},
	{
		title: 'Use a 24- words recovery phrase',
	},
]
export const Import = () => {
	return (
		<div className='page'>
			<Header title='Import Wallet' />
			<div className='page-inner'>
				<SubHeader
				title='Mnemonics words (BIT39)'
				icon='import-frame'
				/>
				<div className={styles.wrapper}>
				{mockList.map(item => <Button to='/mnemonicWords'
							state={{ wordCount: item.title.replace(/[^\d.]/gi, '') }} variant='default' className={styles.action}>{item.title}</Button>)}
				<div className={styles.separator}>or</div>
				<Button
					onClick={() => console.log('coming soon')}
					variant='default'
					icon='qr-code'
					className={styles.button}
				>Scan QR</Button>
				</div>
			</div>
		</div>
	)
}
