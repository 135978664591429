import React from 'react'
import Svg from '../../svgs/Svg'
import styles from './Header.module.css'
import { useNavigate } from 'react-router-dom'

export const Header = ({ title, woArrow }) => {
	const navigate = useNavigate()
	return (
		<header className={styles.header}>
			{!woArrow && (
				<div className={styles.back} onClick={() => navigate(-1)} style={{cursor: 'pointer'}}>
				<Svg type='arrow-left' />
				</div>
			)}
			<div className={styles.title}>{title}</div>
		</header>
	)
}
