import React from 'react';
import {QrReader} from 'react-qr-reader';
import styles from './scanCamera.module.css'
import {Button, Header} from '../../components';
import classNames from "classnames/bind";

export const ScanCamera = () => {
    const [data, setData] = React.useState('No result');

    return (
        <div className={classNames('page', styles.bg)}>
            <Header title='WalletConnect'/>
            <div className='page-inner'>
                    <QrReader
                        onResult={(result, error) => {
                            if (!!result) {
                                setData(
                                    result
                                        ?.text
                                );
                            }
                            if (!!error) {
                                console.info(error);
                            }
                        }}
                        style={{
                            width: '100%',
                            height: 400
                        }}/>
            </div>
            <div className="page-inner page-align-bottom">
                <div className={styles.actions}>
                    <Button variant='bar' icon='album'>
                        Album
                    </Button>
                    <Button variant='bar' icon='copy'>
                        Paste
                    </Button>
                </div>
            </div>
        </div>
    );
}