import React from 'react';
import styles from './importSettings.module.css';
import { Button, Checkbox, Header, Box } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { setShowAssets } from '../../redux/slices/StorageSlice';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

export const ImportSettings = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { showAssets } = useSelector((state) => state.storage);

	const checkboxListMock = [
		{
			id: 1,
			label: 'Whirlpool',
		},
		{
			id: 2,
			label: 'PSBT Muti-signature',
		},
		{
			id: 3,
			label: 'ERC 20 Assets',
			func: (bool) => dispatch(setShowAssets(bool)),
			enable: showAssets,
		},
	];

	const radioListMock = [
		{
			id: 4,
			label: 'Public Server ',
			subtext: 'Using a public server means it can see your transactions.',
			enable: true,
		},
		{
			id: 5,
			label: 'Bitcoin Core',
			enable: false,
		},
	];

	const checkboxList = checkboxListMock.map((item) => (
		<Box className={styles.row} key={item.id}>
			<Checkbox
				type='checkbox'
				variant='line'
				id={item.id}
				label={item.label}
				item={item}
			/>
		</Box>
	));

	const radioList = radioListMock.map((item) => (
		<div className={styles.row_grid} key={item.id}>
			<Checkbox
				type='radio'
				variant='line'
				id={item.id}
				label={item.label}
				name='radioList'
				subtext={item.subtext}
				item={item}
			/>
		</div>
	));

	const goMain = () => {
		navigate('/main');
	};

	return (
		<div className='page'>
			<Header title='Settings' />
			<div className='page-inner'>
				<div className={styles.list}>{checkboxList}</div>
				<div className={classNames(styles.list, styles.list_grid)}>
					{radioList}
				</div>
				<Button variant='default' onClick={goMain}>
					Continue
				</Button>
			</div>
		</div>
	);
};
