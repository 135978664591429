import React from 'react'
import classNames from 'classnames/bind'
import styles from './Input.module.css'
import Svg from '../../../svgs/Svg'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setValidWords } from '../../../redux/slices/WalletSlice'

export const Input = (props) => {
	const dispatch = useDispatch()
	const { state } = useLocation()
	const [colorBorder, setColorBorder] = React.useState(false)

	React.useEffect(() => {
		if (props.validWord && state !== null) {
			if (props.inputValue === state.phraseArr[props.itemOnce - 1]) {
				setColorBorder(false)
				dispatch(setValidWords([props.index, true]))
			} else if (props.inputValue !== '') {
				setColorBorder(true)
				dispatch(setValidWords([props.index, false]))
			}
		}
	}, [props.validWord, props.inputValue, props.phraseArr, state])

	const sizes = {
		lg: styles.large,
	}

	const inputClasses = classNames(
		styles.inner,
		sizes[props.size],
		props.className,
		props.iconAlign ? styles.icon_align : '',
	);

	return (
		<>
			{props.title && (
				<div className={styles.title}>
				{props.title}
				{props.icon ?
					<Svg type={props.icon} />
					: ''}
				</div>
			)}
			<div className={inputClasses}>
				<input
					disabled={props.disabled}
					style={
						props.validWord
							? !colorBorder
								? { borderColor: '#537EC7' }
								: { borderColor: '#C7537D' }
							: {}
					}
					type="text"
					placeholder={props.placeholder}
					className={classNames(styles.input, props.className)}
					maxLength={props.maxLength}
					ref={props.inputRef}
					value={props.inputValue}
					onChange={(event) =>
						props.handleChange(event.target.value, props.index)
					}
					onPaste={(e) => props.eventPaste(e)}
				/>
			</div>
		</>
	)
}
