import React from 'react'
import cn from 'classnames'
import styles from './copyButton.module.css'
import Svg from './../../../svgs/Svg'

export const CopyButton = ({ textBtn = false, text }) => {
	const [copied, setCopied] = React.useState(false)
	const [timerID, setTimerID] = React.useState(null)

	const onCopy = async () => {
		try {
			await navigator.clipboard.writeText(text)
		} catch (err) {
			console.error('Ошибка при копировании текста: ', err)
		}
		setCopied(true)
		setTimerID(
			setTimeout(() => {
				setCopied(false)
				clearTimeout(timerID)
			}, 1000)
		)
	}

	React.useEffect(() => {
		return () => clearTimeout(timerID)
	}, [])

	return (
		<button className={styles.btn} onClick={onCopy}>
			{textBtn ? (
				<p className={styles.text}>
					{!copied ? <span>Copy Phrase</span> : <span>Copied!</span>}
				</p>
			) : (
				<></>
			)}
			{!copied ? (
				<Svg type='copy' fill='var(--white)' />
			) : (
				<Svg type='check' fill='var(--green)' />
			)}
		</button>
	)
}
