import React from 'react'
import styles from './utxoTerminal.module.css'
import { AnimBlock } from './animBlock'

export const UtxoTerminal = ({ startAnim = false }) => {
	return (
		<div className={styles.terminal}>
			{startAnim && (
				<>
					<AnimBlock time={1000}>
						<span>{'>>'} PING 1.1.1.1 (1.1.1.1): 56 data bytes</span>
					</AnimBlock>
					<AnimBlock time={2000}>
						<span>
							{'>>'}64 bytes from one.one.one.one (1.1.1.1): icmp_seq=0 ttl=58
							time=1.890 ms
						</span>
					</AnimBlock>
					<AnimBlock time={3000}>
						<span>
							{'>>'}64 bytes from one.one.one.one (1.1.1.1): icmp_seq=1 ttl=58
							time=1.840 ms
						</span>
					</AnimBlock>
					<AnimBlock time={4000}>
						<span>
							{'>>'}64 bytes from one.one.one.one (1.1.1.1): icmp_seq=2 ttl=58
							time=1.730 ms
						</span>
					</AnimBlock>
				</>
			)}
		</div>
	)
}
