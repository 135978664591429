import React from 'react'
import styles from './settings.module.css'
import {useState} from 'react'
import {
    Header,
    ItemBlock,
    Popup,
    Button,
    Input,Checkbox
} from '../../components'
import Svg from '../../svgs/Svg'
import {useSelector, useDispatch} from 'react-redux'
import {setAutoLock, setLockWallet, resetWallet} from '../../redux/slices/StorageSlice'
import {useNavigate} from 'react-router-dom'
import version from '../../../package.json'

export const Settings = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [openModalReset, setOpenModalReset] = useState(false)
    const {autoLock, password} = useSelector((state) => state.storage)
    const [disabledBtnReset, setDisabledBtnReset] = React.useState(true)
    const [resetPhrase, setResetPhrase] = React.useState('')

    React.useEffect(() => {
        if (resetPhrase.toLowerCase() === 'reset') {
            setDisabledBtnReset(false)
        } else {
            setDisabledBtnReset(true)
        }
    }, [resetPhrase])

    const handleToggle = (checked) => dispatch(setAutoLock(checked))

    const onResetWallet = () => {
        navigate('/')
        dispatch(resetWallet())
    }

    const lockWallet = () => {
        navigate('/lock')
        dispatch(setLockWallet(true))
    }
    
    const pageItems = [
        {
            title: '',
            list: [
                password !== '' && {
                    id: '0',
                    title: 'Lock Now',
                    icon: 'lock',
                    onClick: lockWallet
                }, {
                    id: '1',
                    title: 'Connected Sites',
                    icon: 'connected',
                    path: '/connectedSites'
                }
            ]
        }, {
            title: 'General',
            list: [
                password !== '' && {
                    id: '5',
                    title: 'Change Password',
                    icon: 'password',
                    path: '/createPassword',
                    state: {to: '/settings'}
                }, {
                    id: '6',
                    title: 'RPC Node',
                    icon: 'rpc',
                    path: '/rpc'
                },  {
                    id: '10',
                    title: 'Sound & Vibration',
                    icon: 'sound',
                    path: '/sound'
                }, {
                    id: '8',
                    title: 'UTXOs',
                    icon: 'time',
                    path: '/utxos'
                }
            ]
        }, 
        {
            title: 'Security',
            list: [
                password !== '' && {
                    id: '9',
                    title: 'Auto-lock',
                    icon: 'lock',
                    children: (<Checkbox
                        isChecked={autoLock}
                        setIsChecked={handleToggle}
                        type='checkbox'
                        variant='line'
                        id='autoLock'
                        name='list'
                      />)
                },
                {
                  id: '7',
                  title: 'Touch ID',
                  icon: 'touchId',
                  children:
                    <Checkbox
                      type='checkbox'
                      variant='line'
                      id='touchId'
                      name='list'
                    />,
                }, {
                    id: '11',
                    title: 'Protection',
                    icon: 'protection',
                    path: '/protection'
                }, {
                    id: '12',
                    title: 'Reset App',
                    icon: 'reset',
                    color: 'var(--error)',
                    onClick: () => setOpenModalReset(true)
                }
            ]
        },
        {
            title: 'Alert',
            list: [
                {
                    id: '13',
                    title: 'Notification',
                    icon: 'notification',
                    path: '/notification'
                }
            ]
        }, {
            title: 'About',
            list: [
                {
                    id: '14',
                    title: 'Version',
                    icon: 'sharp',
                    children: (<span className={styles.inline_text}>{version.version}</span>)
                }, {
                    id: '15',
                    title: 'Language',
                    icon: 'file',
                    children: (
                        <div className={styles.inline_text_icon}>
                            Export
                            <Svg type='import'/>
                        </div>
                    )
                }
            ]
        }
    ]

    return (
        <div className='page'>
            <Header title='Settings'/>
            <div className='page-inner'>
                {
                    pageItems.map((group, index) => (
                        <div className='list-group' key={index}>
                            {group.title && <div className='list-title'>{group.title}</div>}
                            {
                                group
                                    .list
                                    .map((item) => item.id && (<ItemBlock content={item} key={item.id}/>))
                            }
                        </div>
                    ))
                }
                <div className={styles.text}>
                    This will help support debug any issue you might encounter. Please send to
                    <a href="mailto:support@sparrow.com">support@sparrow.com</a>
                    or support only.
                </div>

                {/*Модалка*/}
                <div>
				<Popup
						modalIsOpen={openModalReset}
						type='warning'
						title='Reset app'
						titleTextStyled
						label='Reset app'
						text='This will delete all the data you have created on Arbitrum. After making sure that you have a proper backup, ENTER “RESET” to reset the App'>
						<div className='page-row'>
							<Input
								inputValue={resetPhrase}
								handleChange={setResetPhrase}
								placeholder='Reset'
								size='lg'
							/>
						</div>
						<div className='page-actions'>
							<Button variant='inline' onClick={() => setOpenModalReset(false)}>
								Cancel
							</Button>
							<Button
								variant={disabledBtnReset ? 'disabled' : 'default'}
								onClick={onResetWallet}>
								Reset
							</Button>
						</div>
					</Popup>
                </div>
            </div>
        </div>
    )
}
