import React from 'react';
import styles from './main.module.css';
import {
	Transaction,
	Menu,
	Header,
	Bar,
	MenuSecondary,
} from '../../components';
import { Area, AreaChart, YAxis, XAxis, CartesianGrid } from 'recharts';
import {
	fetchDataWallet,
	fetchChartCoin,
} from '../../redux/slices/WalletSlice';
import { useSelector, useDispatch } from 'react-redux';
import fixNum from '../Func.wallet/fixNum';
import { getContractAddressNulled } from '../Func.wallet/getContractAddressNulled';
import axios from 'axios';
import { setChartArr } from '../../redux/slices/WalletSlice';
import logo from '../../assets/images/logo.png';
import Svg from '../../svgs/Svg';

export const Main = () => {
	const dispatch = useDispatch();
	const { dataUser, showAssets } = useSelector((state) => state.storage);
	const { chartBitcoin, dataWallet, walletNew, chartArr } = useSelector(
		(state) => state.wallet
	);

	const [balanceBtc, setBalanceBtc] = React.useState('');

	React.useEffect(() => {
		if (
			dataWallet !== null &&
			dataWallet.positions &&
			chartArr == null &&
			showAssets
		) {
			const address = getContractAddressNulled(dataWallet, 4);
			function fakeFetch(url) {
				return axios.get(`https://localnetwork.cc/${url}/getChart/day`);
			}
			async function asyncAwaitWay(callback) {
				let results;
				for (const url of address) {
					results = await fakeFetch(url);
					if (results.data?.error) {
					} else {
						let indx = 0;
						const arrNew = results.data?.attributes?.points.map((item) => {
							return {
								name: item[0],
								value: item[1],
							};
						});
						const item = arrNew.filter((item, i) => {
							if (indx == i) {
								indx = indx + 20;
								return item;
							}
						});
						dispatch(setChartArr(item));
					}
				}
			}
			asyncAwaitWay();
		}
	}, [dataWallet, showAssets]);

	React.useEffect(() => {
		if (chartBitcoin == null) {
			dispatch(fetchChartCoin('bitcoin'));
		}
	}, [chartBitcoin]);

	React.useEffect(() => {
		if (dataWallet == null) {
			dispatch(fetchDataWallet([dataUser[0].phrase, walletNew, false]));
		}
		if (dataWallet !== null && dataWallet.positions?.length) {
			const btcItem = dataWallet.positions?.find(
				(item) => item.attributes.fungible_info.symbol.toLowerCase() == 'btc'
			);
			if (btcItem !== undefined) {
				setBalanceBtc(btcItem.attributes.value);
			} else {
				setBalanceBtc('0');
			}
		} else {
			setBalanceBtc('0');
		}
	}, [dataUser, dataWallet, walletNew]);

	const mockOptions = [{ title: 'SATS' }, { title: 'USD' }, { title: 'BTC' }];
	const actionsLinks = [
		{
			icon: 'menu-wallet',
			title: 'Transactions',
			to: '/main',
		},
		{
			icon: 'menu-send',
			title: 'Send',
			to: '/send',
		},
		{
			icon: 'menu-rec',
			title: 'Receive',
			to: '/receive',
		},
	];
	return (
		<div className='page'>
			<Header title='Transactions' woArrow />
			<div className='page-inner'>
				<div className={styles.header}>
					<div className={styles.header_block}>
						<img src={logo} alt='Sparrow' />
						<span>Sparrow Wallet</span>
					</div>
					<Bar variant='inline' options={mockOptions} />
				</div>
				<div className={styles.inner}>
					<div className={styles.backdrop} style={{ flexGrow: 1 }}>
						<div className={styles.header_row}>
							<div className={styles.header_col} style={{ minWidth: 105 }}>
								<div className={styles.header_title}>Balance:</div>
								<div className={styles.header_subtitle}>
									<div className={styles.header_digits}>
										{balanceBtc !== '' && +fixNum(balanceBtc)}
										<span>USD</span>
									</div>
								</div>
								<div className={styles.header_info}>Mempool: 0 USD</div>
								<div className={styles.header_info}>
									Transactions: 0 <Svg type='arrow-down' />
								</div>
							</div>

							<div className={styles.chart}>
								<AreaChart
									width={250}
									height={94}
									domain={['dataMin', 'dataMax']}
									data={chartBitcoin}
									margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
								>
									<defs>
										<linearGradient id='value' x1='0' y1='0' x2='0' y2='1'>
											<stop offset='5%' stopColor='#3DA0E3' stopOpacity={0.5} />
											<stop offset='95%' stopColor='#3DA0E3' stopOpacity={0} />
										</linearGradient>
									</defs>
									<CartesianGrid
										strokeDasharray='2'
										strokeOpacity={1}
										vertical={false}
										stroke='#F3F3F3'
									/>
									<XAxis
										dataKey='date'
										tick={{ fill: '#C5C5C5', fontSize: '10px' }}
										axisLine={{ strokeWidth: 0 }}
									/>
									<YAxis
										domain={['auto', 'dataMax']}
										dataKey='value'
										tick={{ fill: '#C5C5C5', fontSize: '10px' }}
										axisLine={{ strokeWidth: 0 }}
									/>
									<Area
										type='monotone'
										dataKey='value'
										stroke='#3DA0E3'
										fillOpacity={1}
										fill='url(#value)'
									/>
								</AreaChart>
							</div>
						</div>
						<div className={styles.inner_row}>
							<MenuSecondary data={actionsLinks} variant='blue' />
						</div>
						<div className={styles.content} style={{ flexGrow: 1 }}>
							{showAssets &&
							dataWallet !== null &&
							dataWallet.positions?.length ? (
								dataWallet.positions
									.slice(0, 4)
									.map((item, i) => (
										<Transaction
											index={i}
											chart={chartArr}
											data={item}
											key={i}
										/>
									))
							) : (
								<div className={styles.empty}>No transactions yet</div>
							)}
						</div>
					</div>
					<div>
						<Menu />
					</div>
				</div>
			</div>
		</div>
	);
};
