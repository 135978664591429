import React from 'react'
import { Button, Header, FormColumns } from '../../components'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux/es/exports'
import { setWalletNew } from '../../redux/slices/WalletSlice'
import { setData, setIsLogin } from '../../redux/slices/StorageSlice'
import { useLocation, useNavigate } from 'react-router-dom'

export const ConfirmRecoveryWords = () => {
	const {state} = useLocation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { validWords } = useSelector((state) => state.wallet)
	const [disabledBtn, setDisabledBtn] = React.useState(true)
	const [inp1, setInp1] = React.useState('')
	const [inp2, setInp2] = React.useState('')
	const [inp3, setInp3] = React.useState('')

	React.useEffect(() => {
		const valid = validWords.filter((item) => item == false)
		if (valid.length > 0) {
			setDisabledBtn(true)
		} else {
			setDisabledBtn(false)
		}
	}, [validWords])

	const onRegisterWallet = () => {
		if (!disabledBtn) {
			dispatch(setData([state.newUser]))
			dispatch(setWalletNew(true))
			dispatch(setIsLogin(true))
			navigate('/createPassword', {state: {to: '/main'}})
		}
	}

	return (
		<div className='page'>
			<Header title='Recovery words' />
			<div className='page-inner'>
				<FormColumns
					actions={[
						{ setValue: setInp1, value: inp1 },
						{ setValue: setInp2, value: inp2 },
						{ setValue: setInp3, value: inp3 },
					]}
					inputsListOnce={[3, 5, 12]}
				/>
			</div>
			<div className="page-inner page-align-bottom">
				<Button
					variant={disabledBtn ? 'disabled' : 'default'}
					onClick={onRegisterWallet}>
					Continue
				</Button>
			</div>
		</div>
	)
}
