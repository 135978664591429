export function getContractAddressNulled(dataWallet, count = 3) {
	const ethNetwork = ['ethereum', 'arbitrum', 'aurora', 'optimism']
	const coinsContractAddress = dataWallet.positions
		?.slice(0, count)
		.map((item) => item.relationships.fungible.data.id)
	// const newContractAddress = coinsContractAddress.map((item) => {
	// 	if (item !== false) {
	// 		if (item.split('-')[0].length == 42) {
	// 			return item.split('-')[0]
	// 		} else {
	// 			const itemArr = item.toLowerCase().split('-')
	// 			const checkArrEth = itemArr.map((item) => ethNetwork.includes(item))
	// 			if (checkArrEth.includes(true)) {
	// 				return 'eth'
	// 			}
	// 			if (itemArr.includes('avalanche')) {
	// 				return '43e05303-bf43-48df-be45-352d7567ff39'
	// 			}
	// 			if (itemArr.includes('binance')) {
	// 				return '0xb8c77482e45f1f44de1745f52c74426c631bdd52'
	// 			}
	// 			if (itemArr.includes('fantom')) {
	// 				return '0x4e15361fd6b4bb609fa63c81a2be19d873717870'
	// 			}
	// 			if (itemArr.includes('polygon')) {
	// 				return '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0'
	// 			}
	// 			if (itemArr.includes('gnosis')) {
	// 				return 'b99ea659-0ab1-4832-bf44-3bf1cc1acac7'
	// 			}
	// 		}
	// 	}
	// })
	return coinsContractAddress
}
