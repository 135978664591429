import React from 'react';
import {Button, Header, Digits, SubHeader} from '../../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAutoLock, setPassword } from '../../redux/slices/StorageSlice';
import { setPasswordConfirm, setPasswordInit } from '../../redux/slices/WalletSlice';

export const CreatePassword = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {state} = useLocation()
  const {passwordInit, passwordConfirm} = useSelector(state => state.wallet)

  React.useEffect(() => {
      if(passwordInit.length === 6 && passwordConfirm.length === 6 && passwordInit === passwordConfirm) {
        dispatch(setPassword(passwordConfirm))
        dispatch(setAutoLock(true))
        dispatch(setPasswordInit(''))
        dispatch(setPasswordConfirm(''))
        navigate(state.to)
      }
  }, [passwordInit, passwordConfirm])

  return (
    <div className='page'>
      <Header title='Add Password' />
      <div className='page-inner'>
        <SubHeader title='This is needed to open and to recover your wallet. Store it safely, it cannot be changed.' />
        <Digits
          name='create'
          label='Create PIN code'
        />
        <Digits
          name='confirm'
          label='Confirm PIN code'
        />
      </div>
      <div className="page-inner page-align-bottom">
        <div className="page-actions">
          <Button variant='inline' to={state !==  null ? state.to : '/main'}>
            Without Password
          </Button>
          <Button variant='default'>
            Continue
          </Button>
        </div>
      </div>
    </div>
  )
}
