import React from 'react';
import Svg from '../../../svgs/Svg';
import classNames from 'classnames/bind';
import styles from './Button.module.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export const Button = ({
	onClick = false,
	goBack,
	className,
	children,
	variant,
	icon,
	color,
	to = '',
	state,
	w = '100%',
	style,
}) => {
	const navigate = useNavigate();

	const onClickLink = () => {
		if (goBack) {
			navigate(-1);
		} else {
			onClick();
		}
	};

	const variants = {
		outlined: styles.outlined,
		outlinedSm: styles.sm,
		disabled: styles.disabled,
		inline: styles.inline,
		bar: styles.bar,
		start: styles.start,
	};

	const buttonClasses = classNames(styles.btn, variants[variant], className);

	if (to !== '') {
		return (
			<Link
				state={state}
				to={to}
				className={buttonClasses}
				style={{
					backgroundColor: color,
					display: 'flex',
					textDecoration: 'none',
					width: w,
					...style,
				}}
			>
				{icon && (
					<div className={styles.icon}>
						<Svg type={icon} className='icon' />
					</div>
				)}
				{children}
			</Link>
		);
	} else {
		return (
			<button
				onClick={onClickLink}
				className={buttonClasses}
				style={{ backgroundColor: color, ...style }}
			>
				{icon && (
					<div className={styles.icon}>
						<Svg type={icon} className='icon' />
					</div>
				)}
				{children}
			</button>
		);
	}
};
