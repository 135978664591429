import React from 'react'
import styles from './scan.module.css'
import { Header } from '../../components'
import Svg from '../../svgs/Svg'
import QRCode from 'react-qr-code'

export const Scan = () => {
	return (
		<div className='page'>
			<Header title='WalletConnect' />
			<div className='page-inner'>
				<div className={styles.block}>
					<div className={styles.logo}>
						<Svg type='wallet-logo' />
					</div>
					<div className={styles.qr}>
						<div className={styles.bg}>
							<Svg type='qr-bg' />
						</div>
						<div className={styles.qr_block}>
							<QRCode
								size={160}
								value='Demo'
								viewBox={`0 0 160 160`}
							/>
						</div>
					</div>
					<div className={styles.text}>
						Scan this QR Code <br />
						to connect
					</div>
				</div>
			</div>
		</div>
	)
}
