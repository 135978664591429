import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { Start } from '../pages/start/start';
import { Import } from '../pages/import/import';
import { MnemonicWords } from '../pages/mnemonicWords/mnemonicWords';
import { ImportSettings } from '../pages/importSettings/importSettings';
import { CreateWallet } from '../pages/createWallet/createWallet';
import { RecoveryWords } from '../pages/recoveryWords/recoveryWords';
import { ConfirmRecoveryWords } from '../pages/confirmRecoveryWords/confirmRecoveryWords';
import { CreatePassword } from '../pages/Password/CreatePassword';
import { Main } from '../pages/main/main';
import { Send } from '../pages/send/send';
import { Receive } from '../pages/receive/receive';
import { Whirlpool } from '../pages/whirlpool/whirlpool';
import { Whirlpool2 } from '../pages/whirlpool2/whirlpool2';
import { Whirlpool3 } from '../pages/whirlpool3/whirlpool3';
import { Whirlpool4 } from '../pages/whirlpool4/whirlpool4';
import { Settings } from '../pages/settings/settings';
import { ConnectedSites } from '../pages/connectedSites/connectedSites';
import { Scan } from '../pages/scan/scan';
import { Notification } from '../pages/notification/notification';
import { Rpc } from '../pages/rpc/rpc';
import { Utxo } from '../pages/utxo/utxo';
import { Utxos } from '../pages/utxos/utxos';
import { Protection } from '../pages/protection/protection';
import { Lock } from '../pages/Lock/Lock';
import { ScanCamera } from '../pages/scan/scanCamera';
import { Threshold } from '../pages/notification/Threshold';
import { Sound } from '../pages/sound/sound';

import { RouterProvider } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLockWallet } from '../redux/slices/StorageSlice';

export default function () {
	const dispatch = useDispatch();
	const { isLogin, lockWallet, password, autoLock } = useSelector(
		(state) => state.storage
	);

	React.useEffect(() => {
		if (password !== '' && autoLock) {
			dispatch(setLockWallet(true));
		} else {
			dispatch(setLockWallet(false));
		}
	}, [password, autoLock]);
	const [routes, setRoutes] = React.useState(null);

	React.useEffect(() => {
		setRoutes([
			{
				path: '/',
				element:
					password !== '' && lockWallet && isLogin ? (
						<Lock />
					) : isLogin ? (
						<Main />
					) : (
						<Start />
					),
			},
			{
				path: '/',
				element: <Start />,
			},
			{
				path: '/createPassword',
				element: <CreatePassword />,
			},
			{
				path: '/main',
				element: <Main />,
			},
			{
				path: '/sound',
				element: <Sound />,
			},
			{
				path: '/threshold',
				element: <Threshold />,
			},
			{
				path: '/lock',
				element: <Lock />,
			},
			{
				path: '/notification',
				element: <Notification />,
			},
			{
				path: '/protection',
				element: <Protection />,
			},
			{
				path: '/create',
				element: <CreateWallet />,
			},
			{
				path: '/recoveryWords',
				element: <RecoveryWords />,
			},
			{
				path: '/confirmRecoveryWords',
				element: <ConfirmRecoveryWords />,
			},
			{
				path: '/connectedSites',
				element: <ConnectedSites />,
			},
			{
				path: '/scan',
				element: <Scan />,
			},
			{
				path: '/scanCamera',
				element: <ScanCamera />,
			},
			{
				path: '/settings',
				element: <Settings />,
			},
			{
				path: '/utxo',
				element: <Utxo />,
			},
			{
				path: '/utxos',
				element: <Utxos />,
			},
			{
				path: '/rpc',
				element: <Rpc />,
			},
			{
				path: '/import',
				element: <Import />,
			},
			{
				path: '/mnemonicWords',
				element: <MnemonicWords />,
			},
			{
				path: '/importSettings',
				element: <ImportSettings />,
			},
			{
				path: '/send',
				element: <Send />,
			},
			{
				path: '/receive',
				element: <Receive />,
			},
			{
				path: '/whirlpool',
				element: <Whirlpool />,
			},
			{
				path: '/whirlpool2',
				element: <Whirlpool2 />,
			},
			{
				path: '/whirlpool3',
				element: <Whirlpool3 />,
			},
			{
				path: '/whirlpool4',
				element: <Whirlpool4 />,
			},
		]);
	}, [isLogin]);

	if (routes == null) {
		return;
	}

	const router = createBrowserRouter(routes);

	return <RouterProvider router={router} />;
}
