import React from 'react'
import styles from './connectedSites.module.css'
import { Header, Input, Button, Popup, SubHeader, CheckboxOriginal } from '../../components'
import Svg from '../../svgs/Svg'
import { useSelector, useDispatch } from 'react-redux'
import {
	setSiteLinks,
	setActiveSiteLink,
} from '../../redux/slices/StorageSlice'
import { Checkbox } from '../../components'
import { useNavigate } from 'react-router-dom'

export const ConnectedSites = () => {
	const { siteLinks, activeSiteLink } = useSelector((state) => state.storage)
	const [openAddModal, setOpenAddModal] = React.useState(false)
	const [disabledBtnAdd, setDisabledBtnAdd] = React.useState(true)
	const [titleLink, setTitleLink] = React.useState('')
	const dispatch = useDispatch()
	const navigation = useNavigate()

	React.useEffect(() => {
		if (
			(titleLink.length > 5 && titleLink.toLowerCase().includes('https:')) ||
			(titleLink.length > 5 && titleLink.toLowerCase().includes('wc:'))
		) {
			setDisabledBtnAdd(false)
		} else {
			setDisabledBtnAdd(true)
		}
	}, [titleLink])

	const addLink = () => {
		dispatch(
			setSiteLinks({
				id: Math.random(),
				title: titleLink,
			})
		)
		dispatch(setActiveSiteLink(titleLink))
		setOpenAddModal(false)
	}

	const chooseLinkSite = (link) => {
		dispatch(setActiveSiteLink(link))
	}

	return (
		<div className='page'>
			<Header title='Connected Sites' />
			<div className='page-inner page'>
				<div className={styles.actions}>
					<Button onClick={() => setOpenAddModal(true)} variant='bar' icon='plus'>
						Add
					</Button>
					<Button w='45%' to='/scanCamera' variant='bar' icon='qr'>
						Scan
					</Button>
					</div>
					<SubHeader
						title='WalletConnect'
						icon='wallet-logo'
					/>
				<div className={styles.info} onClick={() => navigation('/scan')}>
					<Svg type='scan' />
					Scan to Connect
				</div>
				<div className={styles.title} style={{marginBottom: 15}}>Connected Sites</div>
				<div className={styles.content}>
					{siteLinks.length ?
						siteLinks.map((item) => (
							<Item
								activeSiteLink={activeSiteLink}
								onClick={chooseLinkSite}
								title={item.title}
								key={item.id}
								id={item.id}
							/>
						)) : <></>}
					{siteLinks.length ? (
						<></>
					) : (
						<div className={styles.content_empty}>
							<Svg type='inbox' />
							<span>No Connected sites</span>
							<div>Creating neew connection with dApps.</div>
						</div>
					)}
				</div>
			</div>

			{/*Модалка*/}
			<div>
				<Popup
					modalIsOpen={openAddModal}
					title='Add new connection'
					type='warning'
					typeIcon='connected'
					label='Add connection'>
					<div className='page-row'>
						<Input
							inputValue={titleLink}
							handleChange={setTitleLink}
							placeholder='Start with "wc:" or "https://"'
							size='lg'
						/>
					</div>
					<div className='page-actions'>
						<Button variant='inline' onClick={() => setOpenAddModal(false)}>
							Cancel
						</Button>
						<Button
							onClick={addLink}
							variant={disabledBtnAdd ? 'disabled' : 'default'}>
							Add
						</Button>
					</div>
				</Popup>
			</div>
		</div>
	)
}

const stylesItem = {
	item: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		backgroundColor: 'var(--primary)',
		borderRadius: 8,
		padding: '15px 20px',
		marginTop: 15,
		cursor: 'pointer',
	},
	span: {
		width: 268,
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		fontWeight: 500,
		fontSize: 16,
		color: 'var(--light)',
	},
}

function Item({ title, id, onClick, activeSiteLink }) {
	return (
		<div style={stylesItem.item} onClick={() => onClick(title)}>
			<span style={stylesItem.span}>{title}</span>
			<Checkbox
				isChecked={title == activeSiteLink}
				type='radio'
				variant='line'
				id={id}
				name='site-links'
			/>
		</div>
	)
}
