import React from 'react'
import { useRef } from 'react'
import styles from './receive.module.css'
import { Header, Input, Button } from '../../components'
import Svg from '../../svgs/Svg'
import classNames from 'classnames/bind'
import QRCode from 'react-qr-code'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAddressBitcoin } from '../../redux/slices/WalletSlice'

export const Receive = () => {
	const { dataUser } = useSelector((state) => state.storage)
	const { addressBitcoin, statusAddress } = useSelector((state) => state.wallet)
	const inputRef = useRef()
	const dispatch = useDispatch()
	const [disabledBtn, setDisabledBtn] = React.useState(false)

	React.useEffect(() => {
		if (dataUser.length && addressBitcoin === '') {
			dispatch(fetchAddressBitcoin([dataUser[0].phrase, false, true]))
		}
	}, [dataUser, addressBitcoin])

	React.useEffect(() => {
		if (statusAddress !== 'loading') {
			setDisabledBtn(false)
		} else {
			setDisabledBtn(true)
		}
	}, [statusAddress])

	const onGetNewAddress = () => {
		if (!disabledBtn) {
			dispatch(fetchAddressBitcoin([dataUser[0].phrase, false, true]))
			setDisabledBtn(true)
		}
	}

	const handleCopyToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(addressBitcoin)
		} catch (err) {
			console.error('Ошибка при копировании текста: ', err)
		}
	}

	return (
		<div className='page'>
			<Header title='Receive' />
			<div className={classNames('page-inner', styles.page_inner)}>
				<div className='form-row'>
					<div className={styles.input_wrap}>
						<Input
							disabled
							title='Address:'
							iconAlign
							size='lg'
							inputRef={inputRef}
							inputValue={addressBitcoin}
						/>
						<button onClick={handleCopyToClipboard} className={styles.copy}>
							<Svg type='copy' />
						</button>
					</div>
				</div>

				<div className='page-row'>
					<Input placeholder='Label' size='lg' />
				</div>

				<div className={styles.text_row}>
					<span>Derivation:</span>
					<span>m/84'/0'/0'/0/0</span>
				</div>
				<div className={styles.text_row}>
					<span>Last Used:</span>
					<span>Unknown</span>
				</div>
				{addressBitcoin.length && (
					<div className={styles.block}>
						<div className={styles.block_qr}>
							<div>
								<QRCode
									size={80}
									value={addressBitcoin}
									viewBox={`0 0 80 80`}
								/>
							</div>
						</div>
					</div>
				)}
				<Button
					variant={disabledBtn ? 'disabled' : 'default'}
					icon='refresh'
					onClick={onGetNewAddress}>
					Get Next Address
				</Button>
			</div>
		</div>
	)
}
