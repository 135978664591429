import React from 'react';
import {
	Button,
	Header,
	FormColumns,
	Checkbox,
	Popup,
	Input,
	SubHeader,
} from '../../components';
import styles from './mnemonicWords.module.css';
import Svg from '../../svgs/Svg';
import classNames from 'classnames/bind';
import { useLocation, useNavigate } from 'react-router-dom';
import { setData, setIsLogin } from '../../redux/slices/StorageSlice';
import { useDispatch } from 'react-redux';
import CryptoJS from 'crypto-js';
import generateAddressesFromSeed from '../../hooks/generateAddress';
import Actions from 'reactjs-popup';

const options = [
	'Use 12 Words',
	'Use 15 Words',
	'Use 18 Words',
	'Use 21 Words',
	'Use 24 Words',
];

export const MnemonicWords = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const textRef = React.useRef(null);
	let { state } = useLocation();
	const [wordArr, setWordArr] = React.useState([]);
	const [name, setName] = React.useState('Wallet 1');
	const [checked, setChecked] = React.useState(false);
	const [modalIsOpen, setModalIsOpen] = React.useState(false);
	const [openModalName, setOpenModalName] = React.useState(false);
	const [openAlertModal, setOpenAlertModal] = React.useState(false);
	const [disabledBtn, setDisabledBtn] = React.useState(false);
	const [countArr, setCountArr] = React.useState(12);
	const [disabledBtnName, setDisabledBtnName] = React.useState(true);
	const [timeoutIdPaste, setTimeoutIDPaste] = React.useState(null);
	const [passPhrase, setPassPhrase] = React.useState('');
	const [isOpen, setIsOpen] = React.useState(false);
	const [selectedOption, setSelectedOption] = React.useState(null);

	React.useEffect(() => {
		if (state !== null) {
			setCountArr(state.wordCount);
		}
	}, [state]);

	React.useEffect(() => {
		if (selectedOption !== null) {
			setCountArr(selectedOption.replace(/[^\d.]/gi, ''));
		}
	}, [selectedOption]);

	const handleSelect = (option) => {
		setIsOpen(false);
		setSelectedOption(option);
	};

	React.useEffect(() => {
		return () => {
			clearTimeout(timeoutIdPaste);
		};
	}, []);

	React.useEffect(() => {
		if (name.length > 2) {
			setDisabledBtnName(false);
		} else {
			setDisabledBtnName(true);
		}
	}, [name]);

	React.useEffect(() => {
		if (state !== null) setWordArr(new Array(+countArr).fill(''));
	}, [state]);

	React.useEffect(() => {
		if (selectedOption !== null)
			setWordArr(new Array(+selectedOption.replace(/[^\d.]/gi, '')).fill(''));
	}, [selectedOption]);

	const onImportWallet = () => {
		console.log(openModalName);
		setOpenModalName(true);
	};

	const closeModalAlert = (checked) => {
		setChecked(checked);
		setOpenAlertModal(false);
	};

	const onChangeInput = (text, index) => {
		setWordArr((state) =>
			state.map((item, i) => {
				if (i == index) {
					return text;
				} else {
					return item;
				}
			})
		);
	};

	async function paste() {
		let pasteText = textRef?.current;
		pasteText.value = '';
		pasteText.focus();
		navigator.clipboard.readText().then((cliptext) => {
			(pasteText.value = cliptext), (err) => console.log(err);
			const phraseArr = pasteText.value.trim().split(' ');
			let emptyValue = [];
			if (phraseArr.length < countArr) {
				emptyValue = new Array(countArr - +phraseArr.length).fill('');
				phraseArr.push(...emptyValue);
			}
			setWordArr([]);
			setWordArr(phraseArr);
		});
	}

	React.useEffect(() => {
		if (wordArr.length) {
			const empty = wordArr.filter((item) => item == '');
			if (empty.length) {
				setDisabledBtn(true);
			} else {
				setDisabledBtn(false);
			}
		}
	}, [wordArr]);

	const addNewAcc = () => {
		const kitkat = 'Qsx@ah&OR82WX9T6gCt';
		const genRes = generateAddressesFromSeed(wordArr.join(' '), wordArr.length);
		if (!disabledBtnName) {
			dispatch(
				setData([
					{
						name,
						phrase: CryptoJS.AES.encrypt(wordArr.join(' '), kitkat).toString(),
						privateKey: CryptoJS.AES.encrypt(genRes, kitkat).toString(),
					},
				])
			);
			dispatch(setIsLogin(true));
			setOpenModalName(false);
			navigate('/createPassword', { state: { to: '/importSettings' } });
		}
	};

	const eventPaste = (e) => {
		setTimeoutIDPaste(
			setTimeout(() => {
				const text = e.target.value;
				const textArr = text.trim().split(' ');
				let emptyValue = [];
				if (textArr.length < countArr) {
					emptyValue = new Array(countArr - +textArr.length).fill('');
					textArr.push(...emptyValue);
				}
				setWordArr([]);
				setWordArr(textArr);
				clearTimeout(timeoutIdPaste);
			}, 20)
		);
	};

	return (
		<div className='page'>
			<input
				type='text'
				ref={textRef}
				style={{ position: 'absolute', left: -1000, opacity: 0 }}
			/>
			<Header title='Mnemonic Words (BIP39)' />
			<div className='page-inner'>
				<SubHeader title='Mnemonics words' subtitle='Enter word list:'>
					<Actions
						onOpen={() => setIsOpen(!isOpen)}
						onClose={() => setIsOpen(false)}
						open={isOpen}
						trigger={
							<div className={styles.trigger}>
								{selectedOption || 'Select an option'}
							</div>
						}
						position='bottom center'
						className={styles.actions_wrap}
					>
						<div>
							{options.map((option, index) => (
								<div
									key={index}
									onClick={() => handleSelect(option)}
									className={styles.action}
								>
									{option}
								</div>
							))}
							<div className={styles.action}>
								Scan QR
								<Svg type='qr' />
							</div>
						</div>
					</Actions>
				</SubHeader>
				{/* <Item
					title={`Use ${state !== null && countArr} Words`}
					subTitle='Enter word list'
					icon='key'
					dropdownList={mockList}
				/> */}

				<FormColumns
					eventPaste={eventPaste}
					paste={paste}
					actions={onChangeInput}
					inputsList={wordArr}
					valueInput
				/>
				<div className={styles.actions}>
					<Checkbox
						onClick={() => setOpenAlertModal(true)}
						isChecked={checked}
						type='checkbox'
						id='phrase'
						label='Use passphrase'
						variant='line'
					/>
					{!disabledBtn && (
						<div className={classNames(styles.status, styles.status_green)}>
							<span className={styles.status_text}>Valid checksum</span>
							<Svg type='check-circle' />
						</div>
					)}
				</div>
				{checked && (
					<div className='page-row'>
						<Input
							value={passPhrase}
							handleChange={setPassPhrase}
							placeholder='Type passphrase here'
							size='lg'
						/>
					</div>
				)}

				{/*Модалки*/}
				<div>
					<Popup
						modalIsOpen={modalIsOpen}
						type='warning'
						title='warning'
						label='Warning popup'
						text='Please, check you mnemonic words again!'
					>
						<Button variant='default' onClick={() => setModalIsOpen(false)}>
							OK
						</Button>
					</Popup>

					<Popup
						modalIsOpen={openAlertModal}
						type='warning'
						title='warning'
						label='Warning popup 2'
						text='A passphrase is not a password! Any variation entered in future loads a valid wallet, but with different addresses. This feature provides optional added security for advanced users only.'
					>
						<a href='/' className='popup_link'>
							Add a passphrase?
						</a>
						<Button onClick={() => closeModalAlert(true)} variant='default'>
							Yes
						</Button>
						<Button variant='inline' onClick={() => closeModalAlert(false)}>
							No, take me back
						</Button>
					</Popup>

					<Popup
						modalIsOpen={openModalName}
						title='Enter wallet name'
						label='Wallet name'
					>
						<div className='page-row'>
							<Input
								inputValue={name}
								handleChange={setName}
								placeholder='Wallet Name'
								icon='wallet'
								size='lg'
							/>
						</div>
						<Button
							variant={disabledBtnName ? 'disabled' : 'default'}
							onClick={addNewAcc}
						>
							Import Wallet
						</Button>
						<Button variant='inline' onClick={() => setOpenModalName(false)}>
							Cancel
						</Button>
					</Popup>
				</div>
			</div>
			<div
				className='page-align-bottom'
				style={{ padding: '0 var(--app-gap) var(--app-gap)' }}
			>
				<Button
					style={{ marginBottom: 0 }}
					onClick={onImportWallet}
					variant={disabledBtn ? 'disabled' : 'default'}
				>
					Import Wallet
				</Button>
			</div>
		</div>
	);
};
