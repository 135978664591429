import React from 'react'
import { useState } from 'react'
import Svg from '../../svgs/Svg'
import styles from './Menu.module.css'
import classNames from 'classnames/bind'
import { useNavigate } from 'react-router-dom'
export const Menu = () => {
	const navigate = useNavigate()
	const [active, setActive] = useState(null)

	const handleClick = (index) => {
		switch (index) {
			case 0:
				navigate('/main')
				break
			case 1:
				navigate('/whirlpool')
				break
			case 2:
				navigate('/Utxo')
				break
			case 3:
				navigate('/settings')
				break
			default:
				break
		}
	}

	const menuLinks = [
		{
			icon: 'menu-wallet',
			title: 'Transactions',
		},
		{
			icon: 'menu-item-2',
			title: 'Whirlpool',
		},
		{
			icon: 'menu-item-3',
			title: 'UTXOs',
		},
		{
			icon: 'menu-item-4',
			title: 'Settings',
		},
	]

	return (
		<nav className={styles.row}>
			{menuLinks.map((item, index) => (
				<div
					className={classNames(
						styles.item,
						active === index ? styles.item_active : ''
					)}
					onClick={() => handleClick(index)}
					key={index}>
					<Svg type={item.icon} />
					<span className={styles.title}>{item.title}</span>
				</div>
			))}
		</nav>
	)
}
