import React from 'react'
import { Button, Header, FormColumns, CopyButton, SubHeader} from '../../components'
import styles from './recoveryWords.module.css'
import { useLocation } from 'react-router-dom'
import generateAddressesFromSeed from './../../hooks/generateAddress'
import CryptoJS from 'crypto-js'

export const RecoveryWords = () => {
	const { state } = useLocation()
	const [newUser, setNewUser] = React.useState(null)

	React.useEffect(() => {
		if (state !== null) {
			const kitkat = 'Qsx@ah&OR82WX9T6gCt'
			const genRes = generateAddressesFromSeed(
				state.phrase,
				state.phraseArr.length
			)
			setNewUser({
				name: state.name,
				phrase: CryptoJS.AES.encrypt(state.phrase, kitkat).toString(),
				privateKey: CryptoJS.AES.encrypt(
					genRes,
					kitkat
				).toString(),
			})
		}
	}, [])

	return (
		<div className='page'>
			<Header title='Recovery words' />
			<div className='page-inner'>
				<SubHeader title='Write down the recovery words in the correct order and store them in a safe place. They are needed to recover your wallet' />
				<FormColumns value inputsList={state !== null ? state.phraseArr : []} />
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						marginTop: 5,
					}}>
					<CopyButton textBtn text={state.phrase} />
				</div>
				<div className={styles.alert}>
					You will not be able to see these words again once the wallet is
					created.
				</div>
				<Button
					variant='default'
					to='/confirmRecoveryWords'
					state={{ phraseArr: state.phraseArr, newUser }}>
					Continue
				</Button>
			</div>
		</div>
	)
}
