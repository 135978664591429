import React from 'react'
import styles from './Transaction.module.css'
import { Box, Chart } from '../'
import fixNum from './../../pages/Func.wallet/fixNum'
import Web3 from 'web3'

export const Transaction = ({ data, chart, index }) => {
	return (
		<Box className={styles.box}>
			<div className={styles.inner}>
				<div className={styles.logo}>
					<img src={data.attributes.fungible_info.icon.url} alt={data.title} />
				</div>
				<div className={styles.name} style={{ width: 100 }}>
					{data.attributes.fungible_info.symbol}
					<span
						style={{
							width: 90,
							textOverflow: 'ellipsis',
							overflow: 'hidden',
							whiteSpace: 'nowrap',
						}}>
						{data.attributes.fungible_info.name}
					</span>
				</div>
				<div className={styles.chart}>
					{chart !== null && chart[index] ? (
						<Chart
							data={chart[index]}
							color={data.attributes.changes.percent_1d}
						/>
					) : (
						<></>
					)}
				</div>
				<div className={styles.info}>
					{fixNum(
						Web3.utils.fromWei(
							data.attributes.quantity.int.toString(),
							data.attributes.quantity.decimals === 6 ? 'mwei' : 'ether'
						)
					) +
						' ' +
						data.attributes.fungible_info.symbol}
					<span>{'$' + fixNum(data.attributes.value)}</span>
				</div>
			</div>
		</Box>
	)
}
