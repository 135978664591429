import React from 'react'
import { Area, AreaChart, YAxis } from 'recharts'

export const Chart = ({ data, color }) => {
	return (
		<AreaChart
			width={70}
			height={35}
			data={data}
			margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
			<YAxis domain={['auto', 'dataMax']} hide={true} />
			<Area
				baseValue='dataMax'
				type='monotone'
				dataKey='value'
				stroke={+color < 0 ? 'var(--error)' : 'var(--green)'}
				fillOpacity={0}
			/>
		</AreaChart>
	)
}
