import React from 'react'
import styles from './FormColumns.module.css'
import { Input, Box } from '../'
import Svg from '../../svgs/Svg'

export const FormColumns = ({
	actions = false,
	value = false,
	inputsList,
	inputsListOnce,
	valueInput,
	paste = false,
	eventPaste,
}) => {
	if (inputsList) {
		var halfList = Math.floor(inputsList.length / 2)
		var firstColumn = inputsList.slice(0, halfList)
		var secondColumn = inputsList.slice(halfList)
	}

	return (
		<div className={styles.box}>
			{inputsList ? (
				<div
					className={styles.inner_row}
					style={paste ? { paddingBottom: 50 } : {}}>
					<div className={styles.inner_col}>
						{firstColumn.map((item, i) => (
							<div className={styles.item} key={'0' + i}>
								<span className={styles.item_index}>{i + 1}.</span>
								<Input
									eventPaste={eventPaste}
									index={i}
									handleChange={actions}
									disabled={value && true}
									inputValue={value ? item : valueInput ? item : ''}
								/>
							</div>
						))}
					</div>
					<div className={styles.inner_col}>
						{secondColumn.map((item, i) => (
							<div className={styles.item} key={i}>
								<span className={styles.item_index}>{i + halfList + 1}.</span>
								<Input
									eventPaste={eventPaste}
									index={i + halfList}
									handleChange={actions}
									disabled={value && true}
									inputValue={value ? item : valueInput ? item : ''}
								/>
							</div>
						))}
					</div>
					{paste && (
						<span className={styles.pasteBtn} onClick={paste}>
							Insert phrase
							<Svg type='copy' />
						</span>
					)}
				</div>
			) : (
				<div className={styles.inner_row_once}>
					<div className={styles.inner_col}>
						{inputsListOnce.map((item, i) => (
							<div key={i}>
								<div className={styles.label}>Enter a recovery word {item}</div>
								<div className={styles.item} key={i}>
									<span className={styles.item_index}>{item}.</span>
									<Input
										index={i}
										itemOnce={item}
										validWord
										handleChange={actions && actions[i].setValue}
										inputValue={actions && actions[i].value}
									/>
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	)
}
