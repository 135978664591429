import React from 'react'
import cn from 'classnames'
import styles from './utxoTerminal.module.css'

export const AnimBlock = ({ time, children }) => {
	const [timerID, setTimerID] = React.useState(null)
	const [isShow, setIsShow] = React.useState(false)
	React.useEffect(() => {
		setTimerID(
			setTimeout(() => {
				setIsShow(true)
				clearTimeout(timerID)
			}, time)
		)

		return () => clearTimeout(timerID)
	}, [])
	return (
		<div
			className={cn(styles.itemShow, {
				[styles.show]: isShow,
			})}>
			{children}
		</div>
	)
}
