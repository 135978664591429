import React from 'react'
import cn from 'classnames'
import styles from './lock.module.css'
import { Digits, Button, Popup, Input } from '../../components'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setLockWallet, resetWallet } from '../../redux/slices/StorageSlice'

export const Lock = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [disabledBtn, setDisabledBtn] = React.useState(false)
	const [validInp, setValidInp] = React.useState(false)
	const { passwordInit } = useSelector((state) => state.wallet)
	const { password } = useSelector((state) => state.storage)
	const [disabledBtnReset, setDisabledBtnReset] = React.useState(true)
	const [resetPhrase, setResetPhrase] = React.useState('')
	const [openModalReset, setOpenModalReset] = React.useState(false)

	const onResetWallet = () => {
		navigate('/start')
		dispatch(resetWallet())
	}

	React.useEffect(() => {
		if (resetPhrase.toLowerCase() === 'reset') {
			setDisabledBtnReset(false)
		} else {
			setDisabledBtnReset(true)
		}
	}, [resetPhrase])

	React.useEffect(() => {
		if (password === passwordInit) {
			setValidInp(true)
			setDisabledBtn(false)
		} else {
			setDisabledBtn(true)
			setValidInp(false)
		}
	}, [passwordInit, password])

	const onLoginWallet = () => {
		if (!disabledBtn) {
			navigate('/main')
			dispatch(setLockWallet(false))
		}
	}

	return (
		<div className={cn('body', styles.wrap)}>
			<div className={styles.body}>
				<h2 className={styles.title}>Enter a password</h2>
				<Digits valid={validInp} name='login' />
			</div>
			<div style={{ marginBottom: 20 }}>
				<Button variant='default' onClick={onLoginWallet}>
					Unlock
				</Button>
				<Button variant='inline' onClick={() => setOpenModalReset(true)}>
					<span style={{ color: 'var(--error)' }}>Reset Wallet</span>
				</Button>
			</div>
			<Popup
				modalIsOpen={openModalReset}
				type='warning'
				title='Reset app'
				titleTextStyled
				label='Reset app'
				text='This will delete all the data you have created on Arbitrum. After making sure that you have a proper backup, ENTER “RESET” to reset the App'>
				<div className='page-row'>
					<Input
						inputValue={resetPhrase}
						handleChange={setResetPhrase}
						placeholder='Reset'
						size='lg'
					/>
				</div>
				<div className='page-actions'>
					<Button variant='inline' onClick={() => setOpenModalReset(false)}>
						Cancel
					</Button>
					<Button
						variant={disabledBtnReset ? 'disabled' : 'default'}
						color='var(--error)'
						onClick={onResetWallet}>
						Reset
					</Button>
				</div>
			</Popup>
		</div>
	)
}
